.fly-icon {
    svg {
        width: 1em;
        height: 1em;
    }

    &.fly-leave {
        &.icon-img.badge-secondary {
            svg {
                fill: var(--shadow-box-color);
            }
        }    
    }
}