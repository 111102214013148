$switchWidth: calc(1.5em + 0.75rem);
$switchHeight: calc(0.75em + 0.375rem);

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: $switchWidth;
    height: $switchHeight;
    margin: 0;
  
    /* Hide default HTML checkbox */
    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .slider {
            background-color: #2196F3;
        }

        &:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
        }

        &:checked + .slider:before {
            -webkit-transform: translateX($switchHeight);
            -ms-transform: translateX($switchHeight);
            transform: translateX($switchHeight);
        }
    }
  
    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;

        &:before {
            position: absolute;
            content: "";
            height: $switchHeight;
            width: $switchHeight;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border: 1px solid black;
        }
    }
  
    /* Rounded sliders */
    .slider.round {
        border-radius: $switchHeight;
    }
    
    .slider.round:before {
        border-radius: 50%;
    }
}