.troll-programmed-actions {
    .status-icon {
        svg {
            font-size: 1.5em;
            width: 1em;
            height: 1em;
            vertical-align: -0.125em
        }
        margin-left: 1px;
    }
}