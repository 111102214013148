@import '../../themes.scss';

.icon-img {
    @include badgeColor;

    svg {
        fill: var(--text-badge-color);

        &.size-x1 {
            width: 1em;
            height: 1em;
        }

        &.size-x2 {
            width: 2em;
            height: 2em;
        }
    }

    &.badge-secondary {
        @include badgeSecondaryColor;

        svg {
            fill: var(--text-badge-secondary-color);
        }
    }
    
    &.icon-button:not(.icon-img-disabled) {
        cursor: pointer;
    }

    &.icon-img-disabled {
        opacity: 0.5;
    }
}