.card-header {    
    .card-header-icons {
        i {
            font-size: 2em;
        }

        svg {
            height: 2em;
            width: 2em;

            &.big-icon {
                height: 3em;
                width: 3em;
            }
        }
    }
}