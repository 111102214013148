@import '../../themes.scss';

$sizes: (
    xl: 7,
    l: 3.7,
    m: 1.8,
    s: 1.4,
    xs: 1.2
);

$zooms: (
    small: 0.5,
    default: 1,
    large: 1.5,
    xLarge: 2
);

@mixin view-map($size, $zoom) {
    $cave-size: 1vw * $size * $zoom;
    $icon-size: $cave-size / 2;

    font-size: $icon-size / 3;

    div.div-table-cell {
        width: $cave-size;
        height: $cave-size;
        max-width: $cave-size;
        max-height: $cave-size;

        .map-cave-details {
            margin-top: $cave-size / 2;
            margin-left: $cave-size / 2;
        }

        &.map-axis {
            font-size: $icon-size * 2 / 3;
        }

        .map-area {
            width: $cave-size;
            height: $cave-size;
            .icon-map {
                font-size: calc(#{$icon-size} - 0.75px);
            }
        }

        .map-area-tooltip {
            width: $cave-size;
            height: $icon-size * 2 / 3;
            line-height: $icon-size * 2 / 3;
            margin-top: -$icon-size * 2 / 3;
            border-top-left-radius: $icon-size / 3;
            border-top-right-radius: $icon-size / 3;
        }
    }

    .map-arrow {
        font-size: $cave-size;

        &.map-arrow-vertical {
            left: calc(#{$cave-size});
            height: calc(#{$cave-size} - 1px);
            line-height: calc(#{$cave-size} - 0.5px);
            width: calc(100% - #{2 * $cave-size} - 1.5px);
        }

        &.map-arrow-horizontal {
            top: calc(#{$cave-size} + 0.5px);
            width: calc(#{$cave-size} - 0.5px);
            height: calc(100% - #{2 * $cave-size} - 0.5px);
            line-height: calc(100% - #{2 * $cave-size} - 0.5px);
        }
    }
}

@mixin colorArea($bg-color, $bg-selected-color) {
    background-color: $bg-color;

    &.area-selected, &.area-selected .map-area-tooltip {
        background-color: $bg-selected-color;
    }
}

.view-map-no-view {
    margin: auto;
    text-align: center;
    font-size: 3em;
    font-weight: bolder;
}

.view-map-container {
    position: relative;
    margin: auto;
    margin-top: 10px;

    .view-map-zoom-container {
        @include legendColor;
        display: inline-block;
        left: 0;
        top: 0;
        border-radius: 0.25rem;
        border: 1px solid var(--text-legend-color);
    }
}

.view-map {
    @include defaultColor;
    margin: auto;
    position: relative;

    border-bottom: 0.5px solid var(--text-color);
    border-left: 0.5px solid var(--text-color);

    @each $size-name, $size in $sizes {
        &.view-map-#{$size-name} {
            @each $zoom-name, $zoom in $zooms {
                &.view-map-#{$zoom-name} {
                    @include view-map($size, $zoom);
                }
            }
        }
    }

    .map-arrow {
        position: absolute;
        z-index: 1;
        cursor: pointer;
        margin: 0;
        padding: 0;
        svg {
            visibility: hidden;
        }
    
        &:hover {
            @include badgeColor;

            svg {
                visibility: visible;
            }
        }

        &.map-arrow-vertical {
            text-align: center;

            &.map-arrow-top {
                top: 0.5px;
            }
    
            &.map-arrow-bottom {
                bottom: 0;
            }
        }

        &.map-arrow-horizontal {
            display: flex;
            align-items: center;

            &.map-arrow-left {
                left: -0.5px;
            }

            &.map-arrow-right {
                right: 1px;
            }
        }
    }
        
    .div-table-cell:not(.map-axis) {
        &.cell-selected {
            background-color: var(--bg-cave-hover);
        }
    }

    div.div-table-cell {
        padding: 0;
        border-top: 0.5px solid var(--text-color);
        border-right: 0.5px solid var(--text-color);

        .map-cave-details {
            position: absolute;
            z-index: 1;
        }

        &.area-selected {
            .map-cave-details {
                @include selectedColor;
                text-align: center;
                border: 1px solid var(--text-selected-color);
                box-shadow: 10px 5px 5px var(--shadow-box-color);
                border-radius: 6px;
                padding: 5px;
                font-size: 0.7rem;
            }
    
            &:not(.map-axis) {
                border-top: none;
                background-color: var(--bg-cave-hover);
                .map-area-tooltip {
                    visibility: inherit;
                    background-color: var(--bg-cave-hover);
                }
            }
        }

        &:not(.map-axis):not(.area-empty) {
            cursor: pointer;
        }

        &.map-axis {
            @include legendColor;
            text-align: center;
            vertical-align: middle;
            overflow: hidden;
        }

        .map-area-tooltip {
            position: absolute;
            background-color: var(--bg-color);
            text-align: center;
            border-top: 1px solid  var(--text-color);
            font-weight: bold;
            visibility: hidden;
        }

        &:not(.map-axis) {
            &.area-highlight {
                @include colorArea(var(--bg-highlight), var(--bg-highlight-hover));
            }
    
            &:not(.area-highlight) {
                &.area-friend {
                    @include colorArea(var(--bg-friend), var(--bg-friend-hover));
                }
    
                &:not(.area-friend) {
                    &.area-enemy {
                        @include colorArea(var(--bg-enemy), var(--bg-enemy-hover));
                    }
    
                    &:not(.area-enemy) {
                        &.area-origin {
                            @include colorArea(var(--bg-origin), var(--bg-origin-hover));
                        }
    
                        &:not(.area-origin) {
                            &.area-place {
                                @include colorArea(var(--bg-place), var(--bg-place-hover));
                            }
                        }
                    }
                }
            }
        }
    }
}