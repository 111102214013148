@import 'bootstrap';

.card-columns.max-columns-count-2 {
    @include media-breakpoint-only(xl) {
      column-count: 2;
    }
    @include media-breakpoint-only(lg) {
      column-count: 2;
    }
    @include media-breakpoint-only(md) {
      column-count: 2;
    }
    @include media-breakpoint-only(sm) {
      column-count: 1;
    }
}