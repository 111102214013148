.div-table {
    display: table;
    
    .div-table-body {
        display: table-row-group;
    }

    .div-table-row {
        display: table-row;

        .div-table-cell {
            display: table-cell;
        }
    }
}