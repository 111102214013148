@import '../../themes.scss';

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html { font-size: 14px; }

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.nav-link {
    @include defaultColor;
}

.navbar-nav {
    &.navbar-tools {
        .nav-link {
            padding: 0.5rem;;
        }
        .switch-theme {
            padding-top: 0.75rem;;
        }
    }
}
