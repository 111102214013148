.map-cave {
    .cave-content {
        white-space: nowrap;
        list-style-type: none;
        margin: 0;
        padding: 0;

        &.cave-trolls {
            li {
                line-height: normal;

                a {
                    color: var(--link-troll);
                }
    
                .guild-troll {
                    font-size: smaller;
                    color: var(--text-secondary-color);
                    padding: 0 2px;
                }
            }
        }
        &.cave-monsters a {
            color: var(--link-monster);
        }
        &.cave-treasures {
            color: var(--text-treasure);
        }
        &.cave-mushrooms {
            color: var(--text-mushroom);
        }
        &.cave-places {
            color: var(text-place);
        }
    }
}