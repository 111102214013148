@import 'bootstrap';
@import '../../themes.scss';

.policy-trolls {
    white-space: nowrap;
    @include media-breakpoint-only(xl) {
        font-size: inherit;
    }
    @include media-breakpoint-only(lg) {
        font-size: inherit;
    }
    @include media-breakpoint-only(md) {
        font-size: small;
    }
    @include media-breakpoint-only(sm) {
        font-size: x-small;
    }
    @include media-breakpoint-only(xs) {
        font-size: xx-small;
    }

    .text-description {
        color: var(--text-place);
    }

    .policy-troll-state {
        text-align: center;
    }

    .policy-badge-troll {
        padding: 0.5em;
    }

    .policy-troll-action {
        padding-left: 0;
        padding-right: 0;
    }
}