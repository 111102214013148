@import 'bootstrap';

.card-columns.troll-cards {
    @include media-breakpoint-only(xl) {
      column-count: 3;
    }
    @include media-breakpoint-only(lg) {
      column-count: 3;
    }
    @include media-breakpoint-only(md) {
      column-count: 2;
    }
    @include media-breakpoint-only(sm) {
      column-count: 2;
    }

    .badge-troll-level {
      height: 2em;
    }
}