@import '../../themes.scss';

.dropdownlist {
    .dropdownlist-content {
        max-height: 20em;
        overflow: auto;
        @include defaultColor;

        .dropdown-item {
            @include defaultColor;
        }

        .dropdown-item:hover {
            @include legendColor;
        }
    }
}