.badge-troll {
    color: white;
    align-items: center;

    &.badge-kastar {
        background-color: darkgreen;
    }
    
    &.badge-durakuir {
        background-color: darkblue;
    }
    
    &.badge-skrim {
        background-color: darkviolet;
    }
    
    &.badge-tomawak {
        background-color: darkred;
    }
    
    &.badge-darkling {
        background-color: darkorange;
    }

    &.badge-nkrwapu {
        background-color: darkslategray;
    }
}