.map-filters {
    .origin-input {
        .origin-col {
            &, input {
                padding: 0;
                margin: 0;
            }
        }
    }
    .range-input {
        width: 50%;
    }
    .view-radius-filter {
        display: inline;

        .view-radius-input {
            display: inline-block;
            width: 4em;
        }
    }
}