/* Generated by Glyphter (http://www.glyphter.com) on  Fri Aug 28 2020*/
@font-face {
    font-family: 'IconMaps';
    src: url('../fonts/IconMaps.eot');
    src: url('../fonts/IconMaps.eot?#iefix') format('embedded-opentype'),
         url('../fonts/IconMaps.woff') format('woff'),
         url('../fonts/IconMaps.ttf') format('truetype'),
         url('../fonts/IconMaps.svg#IconMaps') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'IconMaps';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-troll:before{content:'\0041';}
.icon-monster:before{content:'\0042';}
.icon-treasure:before{content:'\0043';}
.icon-mushroom:before{content:'\0044';}