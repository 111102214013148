.map-area {
    $cave-size: 1em;

    width: $cave-size;
    height: $cave-size;
    line-height: $cave-size;
    
    position: relative;
    left: 0;
    top: 0;

    .icon-map {
        position: absolute;

        &.icon-troll {
            color: var(--icon-troll);
            top: 0.5px;
            left: 0.5px;
        }
        &.icon-monster {
            color: var(--icon-monster);
            top: 0.5px;
            right: 0.5px;
        }
        &.icon-treasure {
            color: var(--icon-treasure);
            bottom: 0.5px;
            left: 0.5px;
        }
        &.icon-mushroom {
            color: var(--icon-mushroom);
            bottom: 0.5px;
            right: 0.5px;
        }
    }
}