@import '../../../themes.scss';

.app-card {
    .card-header {
        @include titleColor;
    }
    .card-body {
        @include defaultColor;
    }
    .card-footer {
        @include legendColor;
    }
}