$themes: (
    default: (
        bg-color: var(--white),
        text-color: var(--dark),
        bg-title-color: rgba(0, 0, 0, .08),
        text-title-color: var(--dark),
        bg-legend-color: rgba(0, 0, 0, .03),
        text-legend-color: var(--text-title-color),
        bg-box-color: lightyellow,
        text-box-color: var(--text-color),
        shadow-box-color: darkgray,
        bg-badge-color: var(--primary),
        text-badge-color: var(--light),
        bg-badge-secondary-color: var(--dark),
        text-badge-secondary-color: var(--light),
        text-bolder-color: darkblue,
        bg-hover: lightgray,
        text-secondary-color: #39424a,
        text-disable-color: lightgray,
        text-selected-color: var(--dark),
        bg-selected-color: thistle,

        //Map
        icon-troll: darkgreen,
        icon-monster: magenta,
        icon-treasure: blue,
        icon-mushroom: maroon,

        // View
        bg-origin: cyan,
        bg-origin-hover: darken(cyan, 10%),
        bg-highlight: yellow,
        bg-highlight-hover: darken(yellow, 10%),
        bg-friend: darken(lime, 10%),
        bg-friend-hover: darken(lime, 20%),
        bg-enemy: red,
        bg-enemy-hover: darken(red, 10%),
        bg-place: lightgray,
        bg-place-hover: darken(lightgray, 10%),
        link-troll: darkblue,
        link-monster: darkred,
        text-treasure: darkgoldenrod,
        text-mushroom: darkorange,
        text-description: gray,
        text-place: lightslategray,
    ),
    dark: (
        bg-color: #171717,
        text-color: var(--light),
        bg-title-color: #3e3e3e,
        text-title-color: var(--light),
        bg-legend-color: #4e4e4e,
        text-legend-color: var(--text-title-color),
        bg-box-color: #A7A7A7,
        text-box-color: var(--text-color),
        shadow-box-color: darkgray,
        bg-badge-color: darkblue,
        text-badge-color: var(--light),
        bg-badge-secondary-color: var(--text-color),
        text-badge-secondary-color: var(--bg-color),
        text-bolder-color: darkgoldenrod,
        bg-hover: darkslategrey,
        text-secondary-color: #39424a,
        text-disable-color: #3a3a3a,
        text-selected-color: var(--dark),
        bg-selected-color: lightyellow,
        //Map
        icon-troll: lime,
        icon-monster: magenta,
        icon-treasure: cyan,
        icon-mushroom: salmon,
        // View
        bg-cave-hover: darken(#3e3e3e, 10%),
        bg-origin: rgb(93, 93, 255),
        bg-origin-hover: darken(rgb(93, 93, 255), 10%),
        bg-highlight: yellow,
        bg-highlight-hover: darken(yellow, 10%),
        bg-friend: darken(lightgreen, 30%),
        bg-friend-hover: darken(lightgreen, 40%),
        bg-enemy: red,
        bg-enemy-hover: darken(red, 10%),
        bg-place: gray,
        bg-place-hover: darken(gray, 10%),
        link-troll: darkblue,
        link-monster: darkred,
        text-treasure: darkgoldenrod,
        text-mushroom: darkorange,
        text-place: lightslategray,
        text-description: gray,
    ),
);

@each $theme, $colors in $themes {
    :root.#{$theme} {
        @each $type, $color in $colors {
            --#{$type}: #{$color};
        }
    }
}

@mixin defaultColor {
    background-color: var(--bg-color);
    color: var(--text-color);
}

@mixin titleColor {
    background-color: var(--bg-title-color);
    color: var(--text-title-color);
}

@mixin legendColor {
    background-color: var(--bg-legend-color);
    color: var(--text-legend-color);
}

@mixin boxColor {
    background-color: var(--bg-box-color);
    color: var(--text-box-color);
}

@mixin badgeColor {
    background-color: var(--bg-badge-color);
    color: var(--text-badge-color);
}

@mixin badgeSecondaryColor {
    background-color: var(--bg-badge-secondary-color);
    color: var(--text-badge-secondary-color);
}

@mixin selectedColor {
    background-color: var(--bg-selected-color);
    color: var(--text-selected-color);
}

:root.theme {
    &, body {
        @include defaultColor;
    }
}