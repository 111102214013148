.troll-header {
  .badge-troll-level {
    height: 2em;
  }
}

.troll-menu-icons {
  text-align: right;

  .icon-img {
    margin-left: 0.25em;
  }
}