.badge-notification {
    position: relative;
    display: inline-block;

    .badge {
        position: absolute;
        border-radius: 50%;
        background-color: red;
        color: white;
        font-size: 0.8em;
        width: 2em;
        height: 2em;
        padding: 0.6em 0;

        &.badge-topright {
            top: -1.5em;
            right: -1.5em;
        }

        &.badge-topleft {
            top: -1.5em;
            left: -1.5em;
        }
    }
}