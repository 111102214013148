.donut {
    .donut-hole {
        fill: transparent;
    }

    .donut-ring, .donut-segment {
        fill: transparent;
        stroke-width: 3;
    }

    .donut-ring {
        stroke: green;
    }

    .donut-segment {
        stroke: red;
    }

    .chart-text {
        font-size: 0.5em;
        fill: var(--text-color);

        text-anchor: middle;
        -moz-transform: translateY(0.25em);
        -ms-transform: translateY(0.25em);
        -webkit-transform: translateY(0.25em);
        transform: translateY(0.25em);

        &:not(.chart-text-with-legend) .chart-legend {
            display: none;
        }

        &.chart-text-with-legend {
            font-size: 0.25em;
            
            .chart-legend {
                -moz-transform: translateY(-0.25em);
                -ms-transform: translateY(-0.25em);
                -webkit-transform: translateY(-0.25em);
                transform: translateY(-0.25em);
            }
            
            .chart-number {
                -moz-transform: translateY(0.75em);
                -ms-transform: translateY(0.75em);
                -webkit-transform: translateY(0.75em);
                transform: translateY(0.75em);
            }
        }
    }
}