@import '../../themes.scss';

.display-equipment {
    .equipment-title {
        .equipment-spell {
            color: var(--text-bolder-color);
            font-weight: bold;
        }

        .equipment-packed {
            color: var(--secondary);
        }
    }
    .equipment-description {
        @include boxColor;
        font-size: small;
        font-style: italic;
    
        &.equipment-description-collapsed {
            display: none;
        }
    }

    &:hover {
        @include titleColor;

        .equipment-description {

            &.equipment-description-collapsed {
                display: inherit;
                position: absolute;
                border: 1px solid var(--text-title-color);
            }
        }
    }
}