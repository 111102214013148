.troll-status {
    text-align: center;

    .status-action-points {
        padding: 1em;
        margin: 0.5em;
    }

    &.float-status-right {
        float: right;
    }

    &.float-status-left {
        float: left;
    }
}

ul.retreats-details {
    list-style-type: none;
    padding-inline-start: 0;
}