.badge-action-points {
    color: white;

    &.badge-ready {
        background-color: green;
    }
    
    &.badge-unready {
        background-color: red;
    }
}