.health-bar {
    background-color: lightgray;

    .health-bar-dying {
        background-color: red;
    }

    .health-bar-very-injured {
        background-color: orange;
    }

    .health-bar-injured {
        background-color: #fff000;
    }

    .health-bar-light-injured {
        background-color: yellowgreen;
    }

    .health-bar-full {
        background-color: green;
    }
}

.health-legend {
    text-align: center;
}