@import '../../themes.scss';

.table-list.table {
    @include defaultColor;
}

.card-body {
    .table-list.table {
      th, td {
        padding: 0;
        border-top: 0;
      }
    }
}
