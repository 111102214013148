$switchHeight: calc(0.75em + 0.375rem);

.switch-theme {
    .switch {
        cursor: pointer;

        input {
            &:checked + .slider {
                background-color: var(--bg-legend-color);

                &+ .light-icon {
                    display: inherit;

                    &+ .dark-icon {
                        display: none;
                    }
                }
            }

            &+ .slider {
                &+ .light-icon {
                    display: none;

                    &+ .dark-icon {
                        display: inherit;
                    }
                }
            }

            &:focus + .slider {
                box-shadow: 0 0 1px var(--bg-legend-color);
            }
        }

        .theme-icon {
            position: absolute;
    
            &.light-icon {
                margin-left: 2px;
                color: yellow;
            }
    
            &.dark-icon {
                -webkit-transform: translateX($switchHeight);
                -ms-transform: translateX($switchHeight);
                transform: translateX($switchHeight);
            }
        }
    }
}